<template>
    <comp-table title="明细" ref="comp_table" :columns="columns" :dataList="dataList">
        <template slot="operate">
            <Button type="primary" style="margin-right: 10px" @click="exportExcel">导出</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "机构名称",
                    key: "orgName",
                },
                {
                    title: "日期",
                    key: "statDate",
                },
                {
                    title: "数量",
                    key: "publishNum",
                },
            ],
            dataList: "",
        }
    },

    mounted() {
        var monthValue = this.$route.query.monthValue
        if (monthValue == "0") {
            var obj = JSON.parse(window.sessionStorage.getItem("detailList3"))
            let keys = Object.keys(obj)
            this.dataList = keys.map(key => ({ orgName: key, publishNum: obj[key], statDate: this.$route.query.time }))
            // this.dataList = JSON.parse(window.sessionStorage.getItem("detailList3"))
        } else {
            this.dataList = JSON.parse(window.sessionStorage.getItem("detailList3")).map(item => {
                return {
                    ...item,
                    statDate: this.$route.query.time,
                }
            })
        }
    },

    methods: {
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["机构名称", "日期", "数量"]
                    let filterVal = ["orgName", "statDate", "publishNum"]
                    let tableData = JSON.parse(JSON.stringify(this.dataList))
                    this.$core.exportExcel(tHeader, filterVal, tableData, "导出明细")
                },
            })
        },
    },
}
</script>
<style lang="less"></style>
